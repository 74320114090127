export default function getConpanyBanner(type) {
    let result = null
    console.log(type);
    switch (type) {
        case 202:
            result = require('@/assets/company_banner/新中亚2(1).jpg')
            break;
        case 12417:
            result = require('@/assets/company_banner/聚鑫成(1).jpg')
            break
    }
    return result
}
